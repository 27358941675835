<!-- 车主认证 -->
<template>
  <div>
    <el-card style="height: 700px; overflow: auto">
      <h3 v-show="ruleForm.auditReject">
        退回理由：{{ ruleForm.auditReject }}
      </h3>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="ruleForm"
      >
        <!-- 车主信息 -->
        <el-form-item
          label-width="135"
          style="width: 100%; border-bottom: 1px solid #ddd"
        >
          <div class="line">车主信息</div>
        </el-form-item>
        <div class="car-info">
          <div>
            <el-form-item label="车主姓名" prop="chargePersonName">
              <el-input
                v-model="ruleForm.chargePersonName"
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="性别" prop="gender">
              <el-select
                size="small"
                v-model="ruleForm.gender"
                placeholder="请选择性别"
              >
                <el-option
                  v-for="item in genderStatus"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="联系电话" prop="chargePersonPhone">
              <el-input
                v-model="ruleForm.chargePersonPhone"
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="身份证" prop="idcard">
              <el-input v-model="ruleForm.idcard" size="small"></el-input>
            </el-form-item>
            <el-form-item label="身份证照片" prop="idcardImg">
              <el-upload
                class="avatar-uploader"
                :action="action"
                :show-file-list="false"
                :on-success="
                  (res, file) => {
                    handleVehicleImg(res, file, 10);
                  }
                "
              >
                <img
                  v-if="ruleForm.idcardImg"
                  :src="ruleForm.idcardImg"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item
              label="车主所在地"
              prop="shopAdressProvince"
              class="shopAdressProvince"
            >
              <div class="address_box">
                <el-select
                  v-model="ruleForm.shopAdressProvince"
                  placeholder="请选择省"
                  @change="(val) => provinceChange(val, 1)"
                  size="small"
                >
                  <el-option
                    v-for="(item, index) in provinceList"
                    :key="index"
                    :label="item.cityName"
                    :value="item.cityId"
                  ></el-option>
                </el-select>
                <el-select
                  v-model="ruleForm.shopAdressCity"
                  placeholder="请选择市"
                  @change="(val) => cityChange(val, 1)"
                  size="small"
                >
                  <el-option
                    v-for="(item, index) in cityList"
                    :key="index"
                    :label="item.cityName"
                    :value="item.cityId"
                  ></el-option>
                </el-select>
                <el-select
                  v-model="ruleForm.shopAdressArea"
                  placeholder="请选择区"
                  @change="(val) => areaChange(val, 1)"
                  size="small"
                >
                  <el-option
                    v-for="(item, index) in areaList"
                    :key="index"
                    :label="item.cityName"
                    :value="item.cityId"
                  ></el-option>
                </el-select>
              </div>
              <el-input
                type="textarea"
                :rows="2"
                v-model="ruleForm.shopAdressDetail"
                placeholder="详细地址"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="银行账号"
              prop="bankAccount"
              class="bankAccount"
            >
              <el-select
                size="small"
                v-model="ruleForm.bankType"
                placeholder="选择账号类型"
              >
                <el-option
                  v-for="item in bankTypeStatus"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                ></el-option>
              </el-select>
              <el-input v-model="ruleForm.bankAccount" size="small"></el-input>
            </el-form-item>
            <el-form-item
              label="个体工商户开户许可证照片/个人银行卡图片"
              prop="bankImg"
              class="bank_avatar"
            >
              <el-upload
                class="avatar-uploader"
                :action="action"
                :show-file-list="false"
                :on-success="
                  (res, file) => {
                    handleVehicleImg(res, file, 9);
                  }
                "
              >
                <img
                  v-if="ruleForm.bankImg"
                  :src="ruleForm.bankImg"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="个体工商户名称" prop="bankName">
              <el-input v-model="ruleForm.bankName" size="small"></el-input>
            </el-form-item>
            <el-form-item
              class="creditCode"
              label="个体统一社会信用代码"
              prop="creditCode"
            >
              <el-input v-model="ruleForm.creditCode" size="small"></el-input>
            </el-form-item>
            <el-form-item label="营业执照" prop="businessLicense">
              <el-upload
                class="avatar-uploader"
                :action="action"
                :show-file-list="false"
                :on-success="
                  (res, file) => {
                    handleVehicleImg(res, file, 11);
                  }
                "
              >
                <img
                  v-if="ruleForm.businessLicense"
                  :src="ruleForm.businessLicense"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="成立时间" prop="establishmentTime">
              <el-date-picker
                v-model="ruleForm.establishmentTime"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="经营范围" prop="businessScope">
              <el-input
                v-model="ruleForm.businessScope"
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="个体工商注册地址"
              prop="bankProvince"
              class="shopAdressProvince"
            >
              <div class="address_box">
                <el-select
                  v-model="ruleForm.bankProvince"
                  placeholder="请选择省"
                  @change="(val) => provinceChange(val, 3)"
                  size="small"
                >
                  <el-option
                    v-for="(item, index) in provinceList"
                    :key="index"
                    :label="item.cityName"
                    :value="item.cityId"
                  ></el-option>
                </el-select>
                <el-select
                  v-model="ruleForm.bankCity"
                  placeholder="请选择市"
                  @change="(val) => cityChange(val, 3)"
                  size="small"
                >
                  <el-option
                    v-for="(item, index) in cityList"
                    :key="index"
                    :label="item.cityName"
                    :value="item.cityId"
                  ></el-option>
                </el-select>
                <el-select
                  v-model="ruleForm.bankArea"
                  placeholder="请选择区"
                  @change="(val) => areaChange(val, 3)"
                  size="small"
                >
                  <el-option
                    v-for="(item, index) in areaList"
                    :key="index"
                    :label="item.cityName"
                    :value="item.cityId"
                  ></el-option>
                </el-select>
              </div>
              <el-input
                type="textarea"
                :rows="2"
                v-model="ruleForm.bankDetail"
                placeholder="详细地址"
              ></el-input>
            </el-form-item>
            <el-form-item label="开户行" prop="bank" label-width="135">
              <el-input v-model="ruleForm.bank" size="small"></el-input>
            </el-form-item>
          </div>
        </div>

        <!-- 车辆信息 -->
        <el-form-item
          label-width="135"
          style="width: 100%; border-bottom: 1px solid #ddd"
        >
          <div class="line">车辆信息</div>
        </el-form-item>
        <el-form-item
          label="车主姓名"
          prop="vehicleOwnerName"
          label-width="135"
        >
          <el-input
            v-model="ruleForm.vehicle.vehicleOwnerName"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item label="机械名称" prop="vehicleName" label-width="135">
          <el-input
            v-model="ruleForm.vehicle.vehicleName"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="companyType" label-width="135">
          <el-cascader
            :options="carStatusList"
            @change="carStatusChange"
            v-model="ruleForm.vehicle.vehicleType"
            :props="optionProps"
            size="small"
            style="width: 50%"
            ref="cascaderAddr"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="品牌" prop="vehicleBrandId" label-width="135">
          <el-select
            size="small"
            v-model="ruleForm.vehicle.vehicleBrandId"
            placeholder="请选择"
            @change="changeBrandId"
          >
            <el-option
              v-for="item in brandList"
              :key="item.id"
              :label="item.brandName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="型号" prop="vehicleModel" label-width="135">
          <el-input
            v-model="ruleForm.vehicle.vehicleModel"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="出厂时间"
          prop="vehicleProductionDate"
          label-width="135"
        >
          <el-date-picker
            size="small"
            value-format="yyyy-MM-dd"
            v-model="ruleForm.vehicle.vehicleProductionDate"
            type="date"
            placeholder="选择日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item
          label="车辆识别码"
          prop="vehicleIdentificationCode"
          label-width="135"
        >
          <el-input
            v-model="ruleForm.vehicle.vehicleIdentificationCode"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="发动机号码"
          prop="vehicleEngineNumber"
          label-width="135"
        >
          <el-input
            v-model="ruleForm.vehicle.vehicleEngineNumber"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item label="设备名称" prop="deviceName" label-width="135">
          <el-input
            v-model="ruleForm.vehicle.deviceName"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item label="设备编号" prop="deviceSn" label-width="135">
          <el-input v-model="ruleForm.vehicle.deviceSn" size="small"></el-input>
        </el-form-item>
        <el-form-item
          label="所在地定位"
          prop="model"
          label-width="135"
          style="width: 100%"
        >
          <div class="address_box">
            <el-select
              v-model="ruleForm.vehicle.vehicleProvince"
              placeholder="请选择省"
              @change="(val) => provinceChange(val, 2)"
              size="small"
            >
              <el-option
                v-for="(item, index) in provinceList"
                :key="index"
                :label="item.cityName"
                :value="item.cityId"
              ></el-option>
            </el-select>
            <el-select
              v-model="ruleForm.vehicle.vehicleCity"
              placeholder="请选择市"
              @change="(val) => cityChange(val, 2)"
              size="small"
            >
              <el-option
                v-for="(item, index) in cityList"
                :key="index"
                :label="item.cityName"
                :value="item.cityId"
              ></el-option>
            </el-select>
            <el-select
              v-model="ruleForm.vehicle.vehicleCounty"
              placeholder="请选择区"
              @change="(val) => areaChange(val, 2)"
              size="small"
            >
              <el-option
                v-for="(item, index) in areaList"
                :key="index"
                :label="item.cityName"
                :value="item.cityId"
              ></el-option>
            </el-select>
          </div>
          <el-input
            type="textarea"
            :rows="2"
            v-model="ruleForm.vehicle.vehicleLocation"
            placeholder="详细地址"
            style="width: 50%; margin-top: 10px; margin-left: 135px"
          ></el-input>
        </el-form-item>
        <div style="width: 100%">
          <el-form-item
            label="租赁方式"
            prop="leaseType"
            label-width="135"
            style="width: 100%"
          >
            <el-select
              size="small"
              v-model="ruleForm.vehicle.leaseType"
              placeholder="请选择"
              @change="changeLeaseType($event)"
              style="width: 25%"
            >
              <el-option
                v-for="v in leaseTypeList"
                :key="v.id"
                :label="v.name"
                :value="v.id"
              >
              </el-option>
            </el-select>
            <!-- <el-input
              v-model="ruleForm.vehicle.leaseWork"
              size="small"
              style="width: 21%; margin: 0 20px"
              v-if="ruleForm.leaseType != ''"
            >
            </el-input> -->
            <el-select
              size="small"
              v-model="ruleForm.vehicle.leaseUnit"
              placeholder="请选择"
              v-if="ruleForm.vehicle.leaseTypeCode == 3"
              style="width: 21%"
              @change="changeLeaseUnit($event)"
            >
              <el-option
                v-for="v in firmNumList"
                :key="v.id"
                :label="v.dictName"
                :value="v.dictId"
              >
              </el-option>
            </el-select>
            <el-input
              v-if="
                ruleForm.vehicle.leaseType != '' &&
                ruleForm.vehicle.leaseTypeCode != 3
              "
              v-model="ruleForm.vehicle.leaseUnit"
              size="small"
              style="width: 21%; margin-left: 20px"
              disabled
            ></el-input>
          </el-form-item>
        </div>
        <el-form-item label="状态" prop="vehicleStatus" label-width="135">
          <el-select
            size="small"
            v-model="ruleForm.vehicle.vehicleStatus"
            placeholder="请选择"
          >
            <el-option
              v-for="item in vehicleStatus"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报价方式" prop="priceTerms" label-width="135">
          <el-select
            size="small"
            v-model="ruleForm.vehicle.priceTerms"
            placeholder="请选择"
          >
            <el-option
              v-for="item in priceTerms"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="报价"
          prop="offer"
          label-width="135"
          style="width: 100%"
        >
          <el-input
            v-model="ruleForm.vehicle.offer"
            size="small"
            style="width: 25%"
          ></el-input>
        </el-form-item>
        <el-form-item label="车辆照片" prop="vehicleImg" label-width="135">
          <el-upload
            class="avatar-uploader"
            :action="action"
            :show-file-list="false"
            :on-success="
              (res, file) => {
                handleVehicleImg(res, file, 1);
              }
            "
          >
            <img
              v-if="ruleForm.vehicle.vehicleImg"
              :src="ruleForm.vehicle.vehicleImg"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item
          label="机械铭牌/合格证"
          prop="vehicleNameplateImg"
          label-width="135"
        >
          <el-upload
            class="avatar-uploader"
            :action="action"
            :show-file-list="false"
            :on-success="
              (res, file) => {
                handleVehicleImg(res, file, 2);
              }
            "
          >
            <img
              v-if="ruleForm.vehicle.vehicleNameplateImg"
              :src="ruleForm.vehicle.vehicleNameplateImg"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="行驶证" prop="dlImg" label-width="135">
          <el-upload
            class="avatar-uploader"
            :action="action"
            :show-file-list="false"
            :on-success="
              (res, file) => {
                handleVehicleImg(res, file, 3);
              }
            "
          >
            <img
              v-if="ruleForm.vehicle.dlImg"
              :src="ruleForm.vehicle.dlImg"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item
          label="车险保单"
          prop="vehicleInsuranceImg"
          label-width="135"
        >
          <el-upload
            class="avatar-uploader"
            :action="action"
            :show-file-list="false"
            :on-success="
              (res, file) => {
                handleVehicleImg(res, file, 4);
              }
            "
          >
            <img
              v-if="ruleForm.vehicle.vehicleInsuranceImg"
              :src="ruleForm.vehicle.vehicleInsuranceImg"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item
          label="车主身份证"
          prop="ownerIdCardImg"
          label-width="135"
          style="width: 100%"
        >
          <el-upload
            class="avatar-uploader"
            :action="action"
            :show-file-list="false"
            :on-success="
              (res, file) => {
                handleVehicleImg(res, file, 5);
              }
            "
          >
            <img
              v-if="ruleForm.vehicle.ownerIdCardImg"
              :src="ruleForm.vehicle.ownerIdCardImg"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <!-- 驾驶员信息 -->
        <el-form-item
          label-width="135"
          style="width: 100%; border-bottom: 1px solid #ddd"
        >
          <div class="line_box">
            <div class="line">驾驶员信息</div>
            <div>
              <el-button class="submit" @click="addDriver"
                >添加驾驶员</el-button
              >
            </div>
          </div>
        </el-form-item>
        <el-form-item style="width: 100%" label-width="135">
          <div
            v-for="(item, index) in ruleForm.vehicle.cerePlatformDriverInfos"
            :key="index"
            class="drive"
          >
            <div class="diver_box">
              <div class="title">姓名</div>
              <el-input v-model="item.driverName" size="small"></el-input>
            </div>
            <div class="diver_box">
              <div class="title">身份证</div>
              <el-autocomplete
                popper-class="my-autocomplete"
                v-model="item.idCard"
                :fetch-suggestions="querySearch"
                placeholder="请输入内容"
                style="width: 50%"
                size="small"
                @select="handleSelect"
              >
              </el-autocomplete>
            </div>
            <div class="diver_box">
              <div class="title">联系电话</div>
              <el-input v-model="item.phone" size="small"></el-input>
            </div>
            <div class="diver_box">
              <div class="title">住址</div>
              <el-input v-model="item.address" size="small"></el-input>
            </div>
            <div class="diver_box">
              <div class="title">驾驶证正本</div>
              <el-upload
                class="avatar-uploader"
                :action="action"
                :show-file-list="false"
                :on-success="
                  (res, file) => {
                    handleVehicleImg(res, file, 6);
                  }
                "
              >
                <img
                  v-if="item.driverLicenseFront"
                  :src="item.driverLicenseFront"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
            <div style="display: flex" class="diver_box">
              <div class="title">驾驶证副本</div>
              <el-upload
                class="avatar-uploader"
                :action="action"
                :show-file-list="false"
                :on-success="
                  (res, file) => {
                    handleVehicleImg(res, file, 7);
                  }
                "
              >
                <img
                  v-if="item.driverLicenseBack"
                  :src="item.driverLicenseBack"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
            <el-button
              v-if="item.delete"
              @click="deleteDriver(index)"
              class="submit"
              style="margin-left: auto"
              >删除</el-button
            >
          </div>
        </el-form-item>
      </el-form>
      <div class="btn">
        <el-button @click="backCar">取消</el-button>
        <el-button @click="submit" class="submit" :loading="loading"
          >提交认证</el-button
        >
      </div>
    </el-card>
  </div>
</template>

<script>
import {
  personalCheck,
  getById,
  getCityTree,
  getDriverByIdCard,
  getCitySelect,
  vehicleTypeTree,
  getBrand,
} from "@/api/user";
import { upload } from "@/api/upload";

export default {
  props: {
    isCarShow: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const validateUsername = (rule, value, callback) => {
      const reg = /^1[3456789]\d{9}$/;
      if (value === "" || !reg.test(value)) {
        return callback(new Error("请输入正确格式的手机号码 "));
      } else {
        callback();
      }
    };
    const validateId = (rule, value, callback) => {
      const reg =
        /^\d{6}(18|19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$/;
      if (value === "" || !reg.test(value)) {
        return callback(new Error("请输入正确格式的身份证号码 "));
      } else {
        callback();
      }
    };
    const validateBank = (rule, value, callback) => {
      const reg = /^([1-9]{1})(\d{15}|\d{18})$/;
      if (value === "" || !reg.test(value)) {
        return callback(new Error("请输入正确格式的身份证号码 "));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      ruleForm: {
        chargePersonName: "",
        bankName: "", // 个体工商户名称
        creditCode: "", // 个体统一社会信用代码
        businessScope: "", // 经营范围
        establishmentTime: "", // 成立时间
        gender: null,
        bankType: null, // 银行账户类型
        chargePersonPhone: "",
        shopAdressDetail: "",
        bankAccount: "",
        bankImg: "",
        shopAdressProvince: "",
        shopAdressProvinceCode: "",
        shopAdressCity: "",
        shopAdressCityCode: "",
        shopAdressArea: "",
        shopAdressAreaCode: "",
        idcard: "",
        idcardImg: "",
        businessLicense: "", // 营业执照
        bankProvince: "", // 个体工商注册地址相关
        bankProvinceCode: "",
        bankCity: "",
        bankCityCode: "",
        bankArea: "",
        bankAreaCode: "",
        bankDetail: "",
        // 车主认证信息
        vehicle: {
          vehicleProvince: "",
          vehicleProvinceCode: "",
          vehicleCity: "",
          vehicleCityCode: "",
          vehicleCounty: "",
          vehicleCountyCode: "",
          vehicleLocation: "",
          // 车主
          vehicleOwnerName: "",
          vehicleName: "",
          vehicleBrandId: null,
          firstLevelType: "",
          firstLevelTypeId: "",
          secondLevelType: "",
          secondLevelTypeId: "",
          vehicleModel: "",
          vehicleProductionDate: "",
          vehicleIdentificationCode: "",
          vehicleEngineNumber: "",
          vehicleStatus: "",
          priceTerms: "",
          offer: "",
          vehicleImg: "",
          vehicleNameplateImg: "",
          dlImg: "",
          vehicleInsuranceImg: "",
          ownerIdCardImg: "",
          vehicleType: 0,
          leaseType: "",
          leaseTypeCode: "",
          leaseWork: null,
          leaseUnit: null,
          // 驾驶员
          cerePlatformDriverInfos: [
            {
              driverName: "",
              idCard: "",
              phone: "",
              address: "",
              driverLicenseFront: "",
              driverLicenseBack: "",
            },
          ],
        },
        companyTypeName: "",
      },
      rules: {
        chargePersonName: [
          { required: true, message: "请输入真实姓名", trigger: "blur" },
        ],
        bankName: [
          { required: true, message: "请输入个体工商户名称", trigger: "blur" },
        ],
        creditCode: [
          {
            required: true,
            message: "请输入个体统一社会信用代码",
            trigger: "blur",
          },
        ],
        businessScope: [
          {
            required: true,
            message: "请输入经营范围",
            trigger: "blur",
          },
        ],
        establishmentTime: [
          {
            required: true,
            message: "请选择成立时间",
            trigger: "blur",
          },
        ],
        gender: [{ required: true, message: "请选择性别", trigger: "change" }],
        shopAdressProvince: [
          { required: true, message: "请选择地区", trigger: "change" },
        ],
        bankProvince: [
          {
            required: true,
            message: "请输入个体工商注册地址",
            trigger: "change",
          },
        ],
        // chargePersonPhone: [
        //   { required: true, validator: validateUsername, trigger: 'blur' },
        // ],
        bank: [{ required: true, message: "请输入开户行", trigger: "blur" }],
        // bankAccount: [
        //   { required: true, validator: validateBank, trigger: 'blur' },
        // ],
        // idcard: [
        //   { required: true, validator: validateId, trigger: 'blur' },
        // ],
        bankImg: [
          { required: true, message: "请上传银行卡照片", trigger: "blur" },
        ],
        idcardImg: [
          { required: true, message: "请上传身份证照片", trigger: "blur" },
        ],
        businessLicense: [
          { required: true, message: "请上传营业执照", trigger: "blur" },
        ],
      },
      provinceList: [],
      cityList: [],
      areaList: [],
      genderStatus: [
        { value: 1, name: "男" },
        { value: 2, name: "女" },
        { value: 3, name: "未知" },
      ],
      bankTypeStatus: [
        { value: "1", name: "个体工商户开户行账号" },
        { value: "2", name: "车主个人开户行账号" },
      ],
      imageUrl: "",
      action: upload,
      optionProps: {
        value: "vehicleTypeId",
        label: "vehicleTypeName",
      },
      carStatusList: [],
      brandList: [],
      vehicleStatus: [
        { value: "0", name: "闲置" },
        { value: "1", name: "工作中" },
        { value: "2", name: "维修中" },
      ],
      priceTerms: [
        { value: "0", name: "带司机" },
        { value: "1", name: "包燃油" },
        { value: "2", name: "包物流" },
      ],
      leaseTypeList: [
        { id: "0", name: "小时" },
        { id: "1", name: "日" },
        { id: "2", name: "月" },
        { id: "3", name: "工作量" },
      ],
      firmNumList: [],

      isShowForm: false,
    };
  },

  created() {
    this.getById();
    this.getCityTree();
    this.vehicleTypeTree();
    getBrand().then((res) => {
      this.brandList = res.data;
    });
  },
  methods: {
    getById() {
      getById().then((res) => {
        if (res.data == null) {
          this.isShowForm = false;
        } else {
          this.isShowForm = true;
        }
        if (res.data != null) {
          this.ruleForm = res.data;
          if (this.ruleForm.idCardImg)
            this.ruleForm.idcardImg = this.ruleForm.idCardImg; // 处理后台返回身份证照片字段不一致的问题
          if (this.ruleForm.vehicle.cerePlatformDriverInfos == null) {
            this.ruleForm.vehicle.cerePlatformDriverInfos = [
              {
                driverName: "",
                idCard: "",
                phone: "",
                address: "",
                driverLicenseFront: "",
                driverLicenseBack: "",
              },
            ];
          }
          if (res.data.shopAdressProvinceCode) {
            getCitySelect({ cityPid: res.data.shopAdressProvinceCode }).then(
              (res) => {
                this.cityList = res.data;
              }
            );
          }
          if (res.data.shopAdressCityCode) {
            getCitySelect({ cityPid: res.data.shopAdressCityCode }).then(
              (res) => {
                this.areaList = res.data;
              }
            );
          }
        }
      });
    },
    vehicleTypeTree() {
      vehicleTypeTree().then((res) => {
        this.carStatusList = res.data;
      });
    },
    backCar() {
      this.$router.go(-1);
    },
    getCityTree() {
      getCityTree().then((res) => {
        this.provinceList = res.data;
      });
    },
    provinceChange(e, index) {
      let provinceObj = {};
      provinceObj = this.provinceList.find((item) => {
        return item.cityId === e;
      });
      // 1 车主所在地 2车辆信息地址-所在地定位 3 个体工商注册地址
      if (index == 1) {
        this.ruleForm.shopAdressProvince = provinceObj.cityName;
        this.ruleForm.shopAdressProvinceCode = provinceObj.cityId;
      } else if (index == 3) {
        this.ruleForm.bankProvince = provinceObj.cityName;
        this.ruleForm.bankProvinceCode = provinceObj.cityId;
      } else {
        this.ruleForm.vehicle.vehicleProvince = provinceObj.cityName;
        this.ruleForm.vehicle.vehicleProvinceCode = provinceObj.cityId;
      }
      getCitySelect({ cityPid: e }).then((res) => {
        this.cityList = res.data;
      });
    },
    cityChange(e, index) {
      let cityObj = {};
      cityObj = this.cityList.find((item) => {
        return item.cityId === e;
      });
      // 1 车主所在地 2车辆信息地址-所在地定位 3 个体工商注册地址
      if (index == 1) {
        this.ruleForm.shopAdressCity = cityObj.cityName;
        this.ruleForm.shopAdressCityCode = cityObj.cityId;
      } else if (index == 3) {
        this.ruleForm.bankCity = cityObj.cityName;
        this.ruleForm.bankCityCode = cityObj.cityId;
      } else {
        this.ruleForm.vehicle.vehicleCity = cityObj.cityName;
        this.ruleForm.vehicle.vehicleCityCode = cityObj.cityId;
      }
      getCitySelect({ cityPid: e }).then((res) => {
        this.areaList = res.data;
      });
    },
    areaChange(e, index) {
      let countyObj = {};
      countyObj = this.areaList.find((item) => {
        return item.cityId === e;
      });
      if (index == 1) {
        this.ruleForm.shopAdressArea = countyObj.cityName;
        this.ruleForm.shopAdressAreaCode = countyObj.cityId;
      } else if (index == 3) {
        this.ruleForm.bankArea = countyObj.cityName;
        this.ruleForm.bankAreaCode = countyObj.cityId;
      } else {
        this.ruleForm.vehicle.vehicleCounty = countyObj.cityName;
        this.ruleForm.vehicle.vehicleCountyCode = countyObj.cityId;
      }
    },
    changeBrandId(e) {
      var objVal = {};
      this.brandList.forEach((val) => {
        if (val.id == e) {
          objVal = val;
        }
      });
      this.ruleForm.vehicle.vehicleBrandId = objVal.id;
      this.ruleForm.vehicle.vehicleBrand = objVal.brandName;
    },
    carStatusChange(value) {
      this.ruleForm.vehicle.firstLevelTypeId = value[0];
      this.ruleForm.vehicle.secondLevelTypeId = value[1];
    },
    changeLeaseType(e) {
      let newObj = {};
      newObj = this.leaseTypeList.find((item) => {
        return item.id === e;
      });
      this.ruleForm.vehicle.leaseType = newObj.name;
      this.ruleForm.vehicle.leaseTypeCode = newObj.id;
      if (e != 3) {
        this.ruleForm.vehicle.leaseUnit = newObj.name;
      } else {
        this.ruleForm.vehicle.leaseUnit = "";
      }
    },
    changeLeaseUnit(e) {
      let newObj = {};
      newObj = this.firmNumList.find((item) => {
        return item.dictId === e;
      });
      this.ruleForm.vehicle.leaseUnit = newObj.dictName;
      this.ruleForm.vehicle.leaseUnitCode = newObj.dictId;
    },
    handleVehicleImg(res, file, index) {
      switch (index) {
        case 1:
          this.ruleForm.vehicle.vehicleImg = res.data.url;
          break;
        case 2:
          this.ruleForm.vehicle.vehicleNameplateImg = res.data.url;
          break;
        case 3:
          this.ruleForm.vehicle.dlImg = res.data.url;
          break;
        case 4:
          this.ruleForm.vehicle.vehicleInsuranceImg = res.data.url;
          break;
        case 5:
          this.ruleForm.vehicle.ownerIdCardImg = res.data.url;
          break;
        case 6:
          this.ruleForm.vehicle.cerePlatformDriverInfos[
            this.ruleForm.vehicle.cerePlatformDriverInfos.length - 1
          ].driverLicenseFront = res.data.url;
          break;
        case 7:
          this.ruleForm.vehicle.cerePlatformDriverInfos[
            this.ruleForm.vehicle.cerePlatformDriverInfos.length - 1
          ].driverLicenseBack = res.data.url;
          break;
        case 9:
          this.ruleForm.bankImg = res.data.url;
          break;
        case 10:
          this.ruleForm.idcardImg = res.data.url;
          break;
        case 11:
          this.ruleForm.businessLicense = res.data.url;
          break;
      }
    },
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    handleSelect(item) {
      getDriverByIdCard({ keyword: item.value }).then((res) => {
        this.ruleForm.vehicle.cerePlatformDriverInfos = res.data;
      });
    },
    addDriver() {
      let isNull = false;
      this.ruleForm.vehicle.cerePlatformDriverInfos.map((item) => {
        let key = Object.keys(item);
        key.map((v) => {
          if (!item[v]) isNull = true;
        });
      });
      if (isNull === true) {
        this.$message.warning("请将数据填写完整");
        return;
      }
      this.ruleForm.vehicle.cerePlatformDriverInfos.push({
        driverName: "",
        idCard: "",
        phone: "",
        address: "",
        driverLicenseFront: "",
        driverLicenseBack: "",
        delete: true,
      });
    },
    deleteDriver(index) {
      this.ruleForm.vehicle.cerePlatformDriverInfos.splice(index, 1);
    },
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          personalCheck(this.ruleForm).then((res) => {
            this.$message({
              message: "提交成功",
              type: "success",
            });
            this.loading = false;
            this.backCar();
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.ruleForm {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  .el-input {
    width: 50%;
  }
  .el-form-item {
    width: 50%;
    margin-bottom: 20px;
  }
  ::v-deep .el-form-item__label {
    width: 135px;
  }
  .el-textarea {
    width: 50%;
  }
  .el-select {
    width: 50%;
  }
  ::v-deep .el-form-item__error {
    left: 30%;
  }
  ::v-deep .el-form-item__error:nth-child(3) {
    left: 15%;
  }
  .address_box {
    display: flex;
    .el-select {
      margin-right: 20px;
      width: 27%;
    }
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .line_box {
    display: flex;
    justify-content: space-between;
  }
  .line {
    border-bottom: 4px solid #fcb110;
    width: 80px;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 146px;
    height: 146px;
    line-height: 146px;
    text-align: center;
    border: 1px dashed #ccc;
  }
  .avatar {
    width: 146px;
    height: 146px;
    display: block;
    object-fit: contain;
  }
  .car-info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    div {
      flex: 1;
      ::v-deep .el-form-item {
        width: 100%;
        .el-form-item__label {
          width: 135px !important;
        }
        .el-form-item__error {
          left: 8%;
        }
        .el-select,
        .el-textarea,
        .el-input {
          width: 225px;
        }
        &.creditCode {
          .el-form-item__label {
            width: 162px !important;
          }
          .el-form-item__error {
            left: 14%;
          }
        }
        &.shopAdressProvince,
        &.bankAccount {
          width: 100%;
          .el-form-item__content {
            width: 70%;
          }
          .address_box {
            display: flex;
            .el-select {
              margin-right: 10px;
              width: 25%;
              .el-input {
                width: 100%;
              }
              &:last-child {
                margin-right: 0;
              }
            }
          }
          .el-textarea {
            width: 225px;
            margin-top: 10px;
            margin-left: 35px;
          }
        }
        &.bankAccount {
          .el-form-item__content {
            display: flex;
          }
          .el-select {
            margin-right: 10px;
            width: 25%;
            .el-input {
              width: 100%;
            }
          }
          .el-input {
            width: 55%;
          }
        }
        .el-date-editor > .el-input__inner {
          height: 32px;
          line-height: 32px;
        }
      }
    }
  }
}

.drive {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  font-size: 14px;
  color: #606266;
  font-weight: 700;
  .diver_box {
    width: 50%;
    display: flex;
    margin-bottom: 10px;
    .title {
      width: 100px;
      margin-right: 20px;
      text-align: right;
    }
  }
}
.btn {
  display: flex;
  justify-content: right;
}
</style>
